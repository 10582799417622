import { getTemplate } from 'o365.modules.helpers.js';
import confirm from 'o365.controls.confirm.js';
import { OTextEditor, ONumberEditor, OBitEditor, ODateEditor, OInputEditor } from 'o365.vue.components.inputEditors.js';
import { h, ref, computed, inject, nextTick, onBeforeMount, toRef, watch, resolveComponent } from 'vue';
import { BatchUpdateController } from 'o365.controls.BatchUpdate.js'

const template = await getTemplate('o365.vue.components.BatchUpdate.html');

function EditorRenderer(props, context) {
    let renderer;
    if (props.override) {
        renderer = override;
    } else if (props.column.batchUpdateEditorSlot) {
        renderer = props.column.batchUpdateEditorSlot;
    } else if (props.column.batchUpdateEditor) {
        renderer = props.column.batchUpdateEditor;
    } else if (props.column.cellEditorSlot) {
        renderer = props.column.cellEditorSlot;
    } else if (props.column.cellEditor) {
        renderer = props.column.cellEditor;
    }

    if (typeof renderer === 'string') {
        const resolve = inject('resolveComponent');
        switch (renderer) {
            case 'OInputEditor':
                renderer = OInputEditor;
                break;
            case 'OTextEditor':
                renderer = OTextEditor;
                break;
            case 'ONumberEditor':
                renderer = ONumberEditor;
                break;
            case 'OBitEditor':
                renderer = OBitEditor;
                break;
            case 'ODateEditor':
                renderer = ODateEditor;
                break;
            default:
                renderer = resolve(renderer) ?? resolveComponent(renderer);
        }
    }

    if (renderer) {
        return h(renderer, {
            modelValue: props.modelValue,
            'onUpdate:modelValue': (value) => context.emit('update:modelValue', value),
            column: props.column
        });
    } else {
        return h('span', { class: 'text-danger' }, 'No editor provided or resolve failed');
    }
};
EditorRenderer.props = ['modelValue', 'column', 'override'];
EditorRenderer.emits = ['update:modelValue'];


const OBatchUpdate = {
    name: 'OBatchUpdate',
    props: {
        dataObject: {
            type: Object,
            required: true
        },
        column: {
            type: Object,
            required: true
        },
        batchUpdateEditor: null,
        batchFields: {
            default(rawProps) {
                return rawProps.column.batchFields ?? [rawProps.column.name]
            }
        },
        dataGridControl: Object,
        width: Number,
        formulaEditor: Boolean,
    },
    components: { EditorRenderer },
    emits: ['oncancel'],
    setup(props, context) {

        const containerRef = ref(null);

        const batchItem = ref({});

         const selectedRows = computed(() => {
            let rows = props.dataObject.data.filter((item) => item._isSelected);
            if (props.dataGridControl && rows.length === 0) {
                const selectedColumn = props.dataGridControl.cellSelectionControl.getColumns().find(x => x.field===props.column.field);
                if (selectedColumn) {
                    rows = props.dataGridControl.cellSelectionControl.getRows();
                }
            }
            return rows;
        });

        const batchUpdateController = ref(new BatchUpdateController({
            dataObject: props.dataObject,
            column: props.column,
            batchFields: props.batchFields,
            selectedRows: selectedRows,
        }));

        const inputPlaceholder = computed(() => {
            const fieldName = props.column.caption;
            return `replace ${fieldName} with...`;
        });

        const containerWidth = computed(() => {
            return props.width ?? props.column?.width ?? 'auto';
        });



        async function handleOk() {
            try {
                if (containerRef.value) {
                    containerRef.value.style.display = 'none';
                }
                await confirm({
                    title: 'Batch update',
                    message: `Are you sure yuo want to update ${props.column.headerName} for the ${selectedRows.value.length} selected records`,
                    btnClassOk: 'btn-danger',
                    btnTextOk: 'Update',
                });
                batchUpdateController.value.doBatchUpdate(batchItem.value);
            } catch (e) {
                handleCancel();
            }
            finally {
                if (containerRef.value) {
                    containerRef.value.style.display = '';
                }
            }
        }

        function handleCancel() {
            context.emit('oncancel');
        }

        function _resetBatchItem() {
            batchItem.value = {};
            props.dataObject.fields.fields.forEach(field => batchItem.value[field.name] = null);
            batchItem.value._isBatchItem = true;
        }

        _resetBatchItem();

        return { selectedRows, inputPlaceholder, containerWidth, handleOk, handleCancel, batchItem, containerRef, batchUpdateController };
    },
    template: template,
};

export { OBatchUpdate };